<template>
  <div class="box-card">
    <el-card>
      <div slot="header" class="tit">
        <h3 class="inBlock">添加模板</h3>
        <back></back>
      </div>
      <div class="box">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item prop="name" label="模板名称">
            <el-input
              size="small"
              v-model="form.name"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <div class="fhdqBox" v-if="isShow">
            <p>发货地区</p>
            <el-form-item label="省:">
              <el-dropdown trigger="click">
                <span style="padding: 5px 20px" class="shou">
                  {{ provice2 }}
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown" trigger>
                  <el-dropdown-item
                    v-for="item in options[adderssIndex]"
                    :key="item.id"
                    @click.native="getprovice(item)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
            <el-form-item label="市:">
              <el-dropdown
                :disabled="options2.length < 1 ? true : false"
                trigger="click"
              >
                <span style="padding: 5px 20px" class="shou">
                  {{ city2 }} <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown" trigger>
                  <el-dropdown-item
                    v-for="item in options2"
                    :key="item.id"
                    @click.native="getcity(item)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
            <el-form-item label="县/区:">
              <el-dropdown
                :disabled="options3.length < 1 ? true : false"
                trigger="click"
              >
                <span style="padding: 5px 20px" class="shou">
                  {{ district2 }}
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown" trigger>
                  <el-dropdown-item
                    v-for="item in options3"
                    :key="item.id"
                    @click.native="getdistrict(item)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </div>
          <el-form-item prop="name" label="不配送区域">
            <el-button type="primary" plain @click="onClickAddBut('NO')"
              >添加地区1</el-button
            >
            <div v-if="prohibitNameArrtest.length > 0" class="zdqyby">
              <span v-for="it in prohibitNameArrtest" :key="it.id">
                {{ it.name }}</span
              >
              地区不配送
            </div>
            <div class="zdqyby" v-else>
              <span>暂无限制下单区域</span>
            </div>
          </el-form-item>
          <div style="margin: 30px 0 30px 0">
            <div class="bottomBox">买家付邮费区域</div>
            <div class="switch">
              <el-radio v-model="radio1" label="1">按重计费</el-radio>
            </div>
            <div
              style="margin-left: 75px"
              v-for="(itemData, index) in form.charge"
              :key="index"
            >
              <div class="bottomBox_item">
                <div class="bottomBox_item_left">
                  <div>
                    <span v-for="(item, index) in itemData.prohibitCitys" :key="index">
                      {{ item.name }}
                    </span>
                  </div>
                  <div style="cursor: pointer" @click="onClickAddBut(index)">
                    +添加地址
                  </div>
                </div>
                <div>
                  <el-form-item prop="name" label="计重收费首重(小)" class="priceinput" label-width="180px">
                    默认运费：首重<el-input
                      v-model="itemData.first_weight"
                      placeholder="请输入"
                    ></el-input
                    >KG以内，<el-input
                      v-model="itemData.first_price"
                      placeholder="请输入"
                    ></el-input
                    >元，每增加<el-input
                      v-model="itemData.continued_weight"
                      placeholder="请输入"
                    ></el-input
                    >KG，增加运费<el-input
                      v-model="itemData.continued_price"
                      placeholder="请输入"
                    ></el-input
                    >元
                  </el-form-item>
                    <el-form-item prop="name" label="计重收费首重(大)" class="priceinput" label-width="180px">
                    默认运费：首重<el-input
                      v-model="itemData.enough_first_weight"
                      placeholder="请输入"
                    ></el-input
                    >KG以内，<el-input
                      v-model="itemData.enough_first_price"
                      placeholder="请输入"
                    ></el-input
                    >元，每增加<el-input
                      v-model="itemData.enough_continued_weight"
                      placeholder="请输入"
                    ></el-input
                    >KG，增加运费<el-input
                      v-model="itemData.enough_continued_price"
                      placeholder="请输入"
                    ></el-input
                    >元
                  </el-form-item>
                    <el-form-item prop="name" label="默认门店承担费用" class="priceinput" label-width="180px">
                   <el-input
                      v-model="itemData.store_price"
                      placeholder="请输入"
                    />元
                  </el-form-item>
                  <!-- <div style="margin-left: 30px">
                    <el-radio v-model="radio1" label="1">指定条件包邮</el-radio>
                    <span style="margin: 0 20px">满</span>
                    <input
                      class="inputStyle"
                      type="text"
                      v-model="itemData.full_price"
                    />
                    <span>元</span>
                    <span>包邮</span>
                  </div> -->
                </div>
              </div>
            </div>
            <el-button
              plain
              @click="addPayAddress"
              style="margin-top: 20px; margin-left: 70px"
              >+添加指定收费区域</el-button
            >
          </div>
        </el-form>
        <div class="submit">
          <span class="shou" @click="submit">确定</span>
          <span class="shou" @click="$router.go(-1)">返回</span>
        </div>
      </div>
      <!-- <el-drawer
        title="我是标题"
        :visible.sync="isOpen.drawer"
        size="50%"
        :with-header="false"
      >
        <span style="display: block; padding: 20px; color: #666">选择地区1</span>
        <div class="provinceName">
          <div
            class="provinceItem"
            v-for="item in options[adderssIndex]"
            :key="item.id"
          >
            <span style="margin-right: 5px" @click="onClickProvince(item)">
              <span
                style="color: #409eff"
                v-if="item.check"
                class="el-icon-circle-check"
              ></span>
              <span
                style="color: #409eff"
                v-else
                class="el-icon-remove-outline"
              ></span>
            </span>
            <span>{{ item.name }}</span>
            <span @click="openPop(item)" class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="secondarealist">
          <el-drawer
            title="选择区域"
            :append-to-body="true"
            :visible.sync="isOpen.innerDrawer"
            v-if="options[adderssIndex]"
          >
            <div
              class="provinceItem"
              v-for="(item, ind) in options[adderssIndex][chooseIndex].children"
              :key="item.id"
            >
              <span
                style="margin-right: 5px; cursor: pointer"
                @click="onClickCity(item, ind)"
              >
                <span
                  style="color: #409eff"
                  v-if="item.check"
                  class="el-icon-circle-check"
                ></span>
                <span
                  style="color: #409eff"
                  v-else
                  class="el-icon-remove-outline"
                ></span>
              </span>
              <span>{{ item.name }}</span>
            </div>
          </el-drawer>
          <div class="yesAndNo">
            <div @click="onClickYes" class="yes">确定</div>
            <div @click="isOpen.drawer = false" class="no">取消</div>
          </div>
        </div>
      </el-drawer> -->
      <!-- 买家付邮费区域 2-->
      <el-drawer
        title="我是标题"
        :visible.sync="isOpen.drawerTwo"
        size="50%"
        :with-header="false"
      >
        <span style="display: block; padding: 20px; color: #666">选择地区2</span>
        <div class="provinceName">
          <template>
             <el-checkbox-group v-model="checkedCities" class="rules-label" v-for="(rule, i) in options[adderssIndex]"  :key="i">
              <el-checkbox :label="rule.id" :checked="rule.check" :value="rule.id" v-model="rule.check" @change="val => handleChecked(i,val,rule)">{{rule.name}}
              </el-checkbox>
              <span @click="openPop(rule)" style="font-size: 15px;margin-top: 3px;" class="el-icon-arrow-right"></span>
            </el-checkbox-group>
          </template>
        </div>
        <div class="secondarealist">
          <el-drawer
            title="选择区域"
            :append-to-body="true"
            :visible.sync="isOpen.innerDrawerTwo"
          >
          <div class="provinceName">
            <template>
              <el-checkbox-group v-model="checkedCitiesChildren" class="rules-label" v-for="(item, index) in options[adderssIndex][chooseIndex].children"  :key="index">
                <el-checkbox :label="item.id" :checked="item.check" :value="item.id" v-model="item.check" @change="val => handleChecked(index,val,item)">{{item.name}}
                </el-checkbox>
                <!-- <span @click="openPop(item)" style="font-size: 15px;margin-top: 3px;" class="el-icon-arrow-right"></span> -->
              </el-checkbox-group>
            </template>
          </div>
            <!-- <div
              class="provinceItem"
              v-for="(item, ind) in options[adderssIndex][chooseIndex].children"
              :key="item.id"
            >
              <span
                style="margin-right: 5px; cursor: pointer"
                @click="onClickCity(item, ind)"
              >
                <span
                  style="color: #409eff"
                  v-if="item.status"
                  class="el-icon-circle-check"
                ></span>
                <span
                  style="color: #409eff"
                  v-else
                  class="el-icon-remove-outline"
                ></span>
              </span>
              <span>{{ item.name }}</span>
            </div> -->
          </el-drawer>
          <!-- <div class="yesAndNo">
            <div @click="onClickYes" class="yes">确定</div>
            <div @click="isOpen.drawer = false" class="no">取消</div>
          </div> -->
        </div>
      </el-drawer>
    </el-card>
  </div>
</template>
<script>
import {
  getarea,
  saveExpressTemplate,
  getExpressTemplate,
  getExpressTemplateDetail,
} from "@/api/api.js";
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
        deliver_province_id: "", // 发货省id
        deliver_city_id: "", // 发货市id
        deliver_area_id: "", // 发货区id
        free_area_id: "", // 指定包邮
        disable_area_id: [], // 禁止配送地区id
        charge: [
          {
            id: "",
            area_id: [],
            first_weight: "", // 首重重量（KG）
            first_price: "", // 首重价格（元）
            continued_weight: "", // 续重（KG）
            continued_price: "", // 续重金额（元）
            express_id: "", // 物流模板id（编辑时用）
            is_setting_free: "2", // 是否指定条件包邮：1.是，2.否
            full_price: "", // 满多少钱
            prohibitCitys: [],// 地址选择
            // template: "", //区分同一区域值判定
            enough_first_weight: "", //（大）首重重量（KG）
            enough_first_price: "", //（大）首重价格（元）
            enough_continued_weight: "", //（大）续重（KG）
            enough_continued_price: "", //（大）续重金额（元）
            store_price: "", //门店需支付的运费金额
            template: 0, //区分是否是同一行数据
          },
        ],
      },
      templatePage: 0,
      radio1: "1",
      switchValue: false,
      options: [], // 首次
      options2: [], // 市
      options3: [], // 区
      prohibitNameArr: [], // 禁止配送省name
      prohibitCity: [],
      prohibitNameArrtest: [], // 配送地区
      allProhibitCity: [],
      provice2: "选择省",
      city2: "选择市",
      district2: "选择区县",
      isOpen: {
        drawer: false,
        innerDrawer: false,
        drawerTwo: false,
        innerDrawerTwo: false,
      },
      chooseIndex: 0,
      isFlag: 1,
      adderssIndex: 0,
      id: "",
      isShow: false,
      checkedCities: [], //地址选择绑定数据
      shiAddress: [], //市级地址
      shiAddressId: [], //市级地址id
      peisong: '',
      checkedCitiesChildren: []
    };
  },
  async created() {
    this.id = this.$route.query.id;
    console.log('this.id===',this.id)
    this.adderssIndex = 0;
    await this.getdiqu(this.id);
    if (this.$route.query.id) {
      console.log('this.$route.query.id====',this.$route.query.id)
      await this.getExpressTemplateDetail({
        id: this.$route.query.id,
      });
    }
  },
  // async mounted() {
  //   this.id = this.$route.query.id;
  //   this.adderssIndex = 0;
  //   await this.getdiqu(this.id);
  //   if (this.$route.query.id) {
  //     console.log('this.$route.query.id====',this.$route.query.id)
  //     await this.getExpressTemplateDetail({
  //       id: this.$route.query.id,
  //     });
  //   }
  // },
  methods: {
    
    // 添加地区不配送区域
    onClickAddBut(val) {
      this.peisong = val;
      this.checkedCities = this.form.disable_area_id
      console.log('this.checkedCities===',this.checkedCities)
      // console.log('this.peisong===',this.peisong)
      this.isOpen.drawerTwo = true;
      this.isFlag = 1;
      console.log(this.form)
    },

    // checkbox选中状态
    handleChecked(i,val,rule){
      rule.check = !rule.check;
      // 禁止配送区域
      if( this.peisong == 'NO'){
        if(rule.check){
          this.prohibitNameArrtest.push(rule)
        }else{
          this.prohibitNameArrtest.forEach( (res, item) => {
            if(!res.check){
               this.prohibitNameArrtest.splice(item, 1)
            }
          })
        }
        // 数据提交地址id
        this.form.disable_area_id = []
        this.prohibitNameArrtest.forEach (req =>{
          if(req.check){
           this.form.disable_area_id.push(req.id)
          }
        })
      }else{
        // 首重地址id
        console.log(' this.form.charge[this.peisong].prohibitCitys====', this.form.charge[this.peisong].prohibitCitys)
        if(rule.check){
          this.form.charge[this.peisong].prohibitCitys.push(rule)
        }else{
          this.form.charge[this.peisong].prohibitCitys.forEach( (res, item) => {
            if(!res.check){
              this.form.charge[this.peisong].prohibitCitys.splice(item, 1)
            }
          })
        }
        this.form.charge[this.peisong].area_id =  []
        this.form.charge[this.peisong].prohibitCitys.forEach (req =>{
          if(req.check){
            this.form.charge[this.peisong].area_id.push(req.id)
          }
        })

      //  console.log('this.form.charge[this.peisong].area_id',this.form.charge[this.peisong].area_id)
      }
      // console.log(' this.prohibitNameArrtest====', this.prohibitNameArrtest)
      //  console.log('i========', i)
      // console.log('val========', val)
      // console.log('rule========', rule)
      // console.log('checkedCities=====',this.form.disable_area_id)
    },
    // 打开省弹窗
    async openPop(item) {
      console.log('item================', item)
      // console.log('777===',isOpen)
      // this.chooseIndex = item.index;
      // if ( this.options[this.adderssIndex][this.chooseIndex].children.length != 0 ) {
      //   if (!isOpen) {
      //     if (this.isFlag == 1) {
      //       this.isOpen.innerDrawer = true;
      //     } else {
      //       this.isOpen.innerDrawerTwo = true;
      //     }
      //   }
      //   return;
      // }
      const { data } = await getarea({ area_id: item.id });
      //   this.prohibitCity = data.data;
      this.options[this.adderssIndex][this.chooseIndex].children = data.data;
      this.options[this.adderssIndex][this.chooseIndex].children.forEach(
        (it) => {
          it.check = item.check;
          it.status = item.status; 
          this.isOpen.innerDrawerTwo = true
        }
      );
      console.log('this.options=======',this.options)
    },


    async getdiqu() {
      // console.log(id,'iddddddddddd');
      // if (this.id) {
      //   const { data } = await getExpressTemplate({ id: this.id });
      //   console.log("回显", data);
      //   this.isShow = true
      // } else {
      const { data } = await getarea();
      data.data.forEach((item, index) => {
        item.children = [];
        item.check = false;
        item.status = false;
        item.index = index;
      });
      this.options[this.adderssIndex] = data.data;
      console.log("options", this.options[this.adderssIndex]);
      this.isShow = true;
      // }
    },
    // onClickAddBut3() {
    //   this.adderssIndex = 0;
    //   if (!this.options[this.adderssIndex]) {
    //     this.getdiqu().then(() => {
    //       this.isOpen.drawerTwo = false;
    //     });
    //   } else {
    //     this.isOpen.drawerTwo = false;
    //   }
    //   this.isFlag = 2;
    //   console.log(this.options, "options===========");
    // },
    // onClickAddBut2(index) {
    //   console.log(index);
    //   this.adderssIndex = index;
    //   if (!this.options[this.adderssIndex]) {
    //     this.getdiqu().then(() => {
    //       this.isOpen.drawerTwo = true;
    //     });
    //   } else {
    //     this.isOpen.drawerTwo = true;
    //   }
    //   this.isFlag = 2;
    //   console.log(this.options, "options===========");
    // },

    // switchChange() {
    //   this.switchValue
    //     ? (this.itemData.is_setting_free = 1)
    //     : (this.itemData.is_setting_free = 2);
    //   console.log(this.itemData.is_setting_free);
    // },
    // 添加收费区域
    addPayAddress() {
      this.templatePage = this.templatePage + 1 
      console.log(this.templatePage, "this.templatePage================");
      this.form.charge.push({
            id: "",
            area_id: [],
            first_weight: "", // 首重重量（KG）
            first_price: "", // 首重价格（元）
            continued_weight: "", // 续重（KG）
            continued_price: "", // 续重金额（元）
            express_id: "", // 物流模板id（编辑时用）
            is_setting_free: "2", // 是否指定条件包邮：1.是，2.否
            full_price: "", // 满多少钱
            prohibitCitys: [],// 地址选择
            // template: "", //区分同一区域值判定
            enough_first_weight: "", //（大）首重重量（KG）
            enough_first_price: "", //（大）首重价格（元）
            enough_continued_weight: "", //（大）续重（KG）
            enough_continued_price: "", //（大）续重金额（元）
            store_price: "", //门店需支付的运费金额
            template: this.templatePage, //区分是否是同一行数据
      });
      console.log(this.form, "form================");
    },
    // onClickYes() {
    //   console.log("address", this.adderssIndex);
    //   if (this.isFlag == 1) {
    //     this.prohibitNameArrtest = []; // 禁止配送地区
    //     this.options[this.adderssIndex].forEach((item) => {
    //       if (item.check) {
    //         this.prohibitNameArrtest.push(item);
    //         return;
    //       }
    //       if (item.children.length) {
    //         item.children.forEach((it) => {
    //           if (it.check) {
    //             this.prohibitNameArrtest.push(it);
    //           }
    //         });
    //       }
    //     });
    //     this.isOpen.drawer = false;
    //   } else {
    //     this.prohibitCity = [];
    //     this.form.charge[this.adderssIndex].area_id = [];
    //     this.form.charge[this.adderssIndex].prohibitCitys = []; // 买家付邮费
    //     this.options[this.adderssIndex].forEach((item) => {
    //       if (item.status) {
    //         console.log(item, "item");
    //         this.prohibitCity.push(item);
    //         console.log(this.adderssIndex);
    //         this.form.charge[this.adderssIndex].prohibitCitys.push(item);
    //         this.form.charge[this.adderssIndex].area_id.push(item.id);
    //         console.log(this.form);
    //         return;
    //       }
    //       if (item.children.length) {
    //         item.children.forEach((it) => {
    //           if (it.status) {
    //             console.log(this.form);
    //             console.log(it, "it");
    //             this.prohibitCity.push(it);
    //             this.form.charge[this.adderssIndex].prohibitCitys.push(it);
    //             this.form.charge[this.adderssIndex].area_id.push(it.id);
    //           }
    //         });
    //       }
    //     });
    //     this.isOpen.drawerTwo = false;
    //     console.log("charge===========", this.form.charge);
    //   }
    // },

    // // 点击省级复选框
    // async onClickProvince(item) {
    //   console.log(this.isFlag)
    //   console.log(item)
    //   if (this.isFlag === 2 && item.check) {
    //     console.log('111')
    //     item.status = false;
    //     item.children.forEach((item2) => {
    //       item2.status = false;
    //     });
    //     return false;
    //   }
    //   if (this.isFlag == 1) {
    //      console.log('222')
    //     item.check = !item.check;
       
    //   } else {
    //      console.log('333') 
    //      this.options[this.adderssIndex].forEach( res =>{
    //       console.log('res===',res)
    //        console.log('item===',item)
    //       if(res.id == item.id){

    //         res.check = !item.check;
    //       }
    //     })
    //     item.status = !item.status;
    //   }
    //   console.log(2, item.check);
    //   console.log(8888, this.options);
    //    console.log(this.adderssIndex);
    //    console.log(item.index);
    //   let isOpen = true;
    //   this.options[this.adderssIndex][item.index].children.forEach((item2) => {
    //     if (this.isFlag == 1) {
    //        console.log('444')
    //       item2.check = item.check;
    //     } else {
    //        console.log('555')
    //       item2.status = item.status;
    //     }
    //   });
    //   this.openPop(item, isOpen);
    //    console.log('66666')
    // },
    // 点击市级复选框
    // onClickCity(item, ind) {
    //   console.log(item);
    //   if (this.isFlag === 2 && item.check === true) {
    //     item.status = false;
    //     return false;
    //   }
    //   let num = 0;
    //   let num2 = 0;
    //   this.options[this.adderssIndex][this.chooseIndex].children.forEach(
    //     (item2, index) => {
    //       if (ind === index) {
    //         if (this.isFlag == 1) {
    //           item2.check = !item2.check;
    //         } else {
    //           item.status = !item2.status;
    //         }
    //         this.$forceUpdate();
    //       }
    //       if (item2.check) {
    //         num++;
    //       } else {
    //         num--;
    //       }
    //       if (item2.status) {
    //         num2++;
    //       } else {
    //         num2--;
    //       }
    //     }
    //   );
    //   if (
    //     Number(num) ===
    //     this.options[this.adderssIndex][this.chooseIndex].children.length
    //   ) {
    //     this.options[this.adderssIndex][this.chooseIndex].check = true;
    //   } else {
    //     this.options[this.adderssIndex][this.chooseIndex].check = false;
    //   }
    //   if (
    //     Number(num2) ===
    //     this.options[this.adderssIndex][this.chooseIndex].children.length
    //   ) {
    //     this.options[this.adderssIndex][this.chooseIndex].status = true;
    //   } else {
    //     this.options[this.adderssIndex][this.chooseIndex].status = false;
    //   }
    // },
    // 物流配送信息详情
    async getExpressTemplateDetail(id) {
      const { data } = await getExpressTemplateDetail(id);
      if (data.code != 200) return this.$message.error(data.data);
      console.log('data.data===',data.data);
      let templateObj = data.data;
      this.form.name = templateObj.name; //标题名称
      this.provice2 = templateObj.deliver_province_name; // 发货省名称
      this.city2 = templateObj.deliver_city_name; // 发货市名称
      this.district2 = templateObj.deliver_area_name;  // 发货区名称
      this.form.deliver_province_id = templateObj.deliver_province_id; // 发货省id
      this.form.deliver_city_id = templateObj.deliver_city_id; // 发货市id
      this.form.deliver_area_id = templateObj.deliver_area_id; // 发货区id
      this.form.disable_area_id = templateObj.disable_area_id; // 详情不配送地址id统计
      // this.prohibitNameArrtest = templateObj.disable_area_name; // 详情不配送地址回显

      templateObj.disable_area_id.forEach( (item, index) =>{ //回显不配送地址信息
        this.options[this.adderssIndex].forEach( res => {
          if(res.id == item){
            res.check = true;
            this.prohibitNameArrtest.push(res)
          }
        })
        this.checkedCities.push(item)
      })
      // 配送地址回显
      this.templatePage = templateObj.express_charge.length - 1
      this.form.charge = []
      templateObj.express_charge.forEach((item, index) =>{ //回显地址对应运费信息
        let obj = {}
        this.form.charge.push({
          id: "",
          area_id: [],
          first_weight: "", // 首重重量（KG）
          first_price: "", // 首重价格（元）
          continued_weight: "", // 续重（KG）
          continued_price: "", // 续重金额（元）
          express_id: "", // 物流模板id（编辑时用）
          is_setting_free: "2", // 是否指定条件包邮：1.是，2.否
          full_price: "", // 满多少钱
          prohibitCitys: [],// 地址选择
          // template: "", //区分同一区域值判定
          enough_first_weight: "", //（大）首重重量（KG）
          enough_first_price: "", //（大）首重价格（元）
          enough_continued_weight: "", //（大）续重（KG）
          enough_continued_price: "", //（大）续重金额（元）
          store_price: "", //门店需支付的运费金额
          template: index, //区分是否是同一行数据
        })

      });
      this.form.charge.forEach((item, index) =>{ //回显地址对应运费信息
      // if(templateObj.express_charge[index].template == index){
        templateObj.express_charge[index].forEach(req =>{
          if(req.template == index){
            this.form.charge[index].area_id.push(req.area_id)
            this.form.charge[index].first_weight = req.first_weight
            this.form.charge[index].first_price  = req.first_price
            this.form.charge[index].continued_weight = req.continued_weight
            this.form.charge[index].continued_price = req.continued_price
            this.form.charge[index].express_id = req.express_id
            this.form.charge[index].is_setting_free = req.is_setting_free
            this.form.charge[index].full_price = req.full_price
            this.form.charge[index].enough_first_weight = req.enough_first_weight
            this.form.charge[index].enough_first_price = req.enough_first_price
            this.form.charge[index].enough_continued_weight = req.enough_continued_weight
            this.form.charge[index].enough_continued_price = req.enough_continued_price
            this.form.charge[index].store_price = req.store_price
            this.form.charge[index].template = req.template
          }

        })
        console.log('his.form.charge[index].area_id====',this.form.charge[index].area_id)
         console.log('his.form.charge[index].area_id====',this.form.charge[index].prohibitCitys)
        // 回显地址信息
        this.form.charge[index].area_id.forEach( areaId =>{
            
          this.options[this.adderssIndex].forEach( res => {
            if(res.id == areaId){
              res.check = true;
              this.form.charge[index].prohibitCitys.push(res)
            }
          })
          this.checkedCities.push(areaId)
        })
      });
      this.from = this.from
      //  console.log(this.form.disable_area_id, this.prohibitNameArrtest)
      // const express_charge = data.data.express_charge;
      //遍历不配送区域
      // for (const key1 in disable_area_id) {
      //   for (const key2 in this.options[0]) {
      //     if (this.options[0][key2].id == disable_area_id[key1]) {
      //       this.prohibitNameArrtest.push(this.options[0][key2]);
      //     }
      //     // for (const key3 in express_charge) {
      //     //   console.log(express_charge);
      //     //   if(express_charge[key3].area_id == this.options[0][key3].id){
      //     //     console.log(this.options[0][key2]);
      //     //   }
      //     // }
      //   }
      // }
      // this.options[0].forEach((item1) => {
      //   express_charge.forEach((item2) => {
      //     if (item2.area_id == item1.id) {
      //       item2.prohibitCitys = [];
      //       item2.prohibitCitys.push(item1);
      //     }
      //   });
      // });
      // this.form.charge = express_charge;
    },
    // 省
    async getprovice(item) {
      this.provice2 = item.name;
      this.form.deliver_province_id = item.id;
      const { data } = await getarea({ area_id: item.id });
      this.options2 = data.data;
      console.log("options2", this.options2);
    },
    // 市
    async getcity(item) {
      this.city2 = item.name;
      this.form.deliver_city_id = item.id;
      const { data } = await getarea({ area_id: item.id });
      this.options3 = data.data;
      console.log("options3", this.options3);
    },
    // 区
    async getdistrict(item) {
      this.district2 = item.name;
      this.form.deliver_area_id = item.id;
    },
    // 禁止地区  省
    async handleCheckedCitiesChange(id) {
      console.log("id", id);
      const { data } = await getarea({ area_id: id });
      this.prohibitCity = data.data;
      // this.getcity();
    },

    // 保存
    submit() {
      console.log();
      if(this.id)(
        this.form.id = this.id
      )
      console.log(' this.form.id====', this.form.id)
      // let disableId = [];
      // let area_id = [];
      // this.prohibitNameArrtest.forEach((k) => {
      //   disableId.push(k.id);
      // });
      // console.log(this.form.charge.prohibitCitys)
      // this.form.charge.prohibitCitys.forEach((j) => {
      //   area_id.push(j.id);
      // });
      // console.log(area_id)
      // console.log("123213", disableId);
      // this.form.disable_area_id = area_id;
      this.form.charge.forEach((item) => {
        delete item.prohibitCitys;
      });
      console.log(this.form.charge);
      // this.form.charge.area_id = area_id;
      saveExpressTemplate(this.form).then((res) => {
        console.log("98989===", res);
        if (res.data.code != 200) return this.$message.error(res.data.data);
        this.$message({
          message: res.data.data,
          type: "success",
        });
        setTimeout(() => {
          this.$router.back(-1);
        }, 500);
      });
    },

  },
};
</script>
<style lang="less" scoped>
.box {
  .el-form {
    .el-form-item {
      .el-dropdown {
        span {
          border: 1px solid #c0c4cc !important;
          color: #777;
        }
      }
      .el-dropdown {
        span {
          padding: 5px 10px;
          border: 1px solid #333;
        }
      }
      // .el-input {
      //     width: 375px;
      // }
      .el-textarea {
        width: 500px;
      }
    }
  }
  .Disable {
    color: #ccc;
  }
  .inputStyle {
    width: 80px;
    height: 40px;
    border-right-color: #fff;
    border: 1px solid #dcdfe6;
    margin-right: 10px;
  }
  .bottomBox {
    font-size: 14px;
    color: #606266;
  }
  .bottomBox_item {
    display: flex;
    margin-bottom: 20px;
    .bottomBox_item_left {
      display: flex;
      color: #fff;
      font-size: 12px;
      flex-direction: column;
      justify-content: space-between;
      width: 200px;
      padding: 15px;
      background-color: #429388;
    }
  }
  .fhdqBox {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      margin-bottom: 22px;
      margin-left: 12px;
    }
  }
  .zdqyby {
    width: 100%;
    background: #eee;
    margin-top: 10px;
    padding-left: 10px;
    span {
      line-height: 40px;
      font-size: 14px;
    }
  }
  .priceinput {
    .el-input {
      width: 100px;
      padding: 0 10px;
    }
  }
}
.el-checkbox-group {
  display: flex;
  margin-left: 20px;
  flex-wrap: wrap;
}
.el-checkbox {
  display: block;
  padding-bottom: 40px;
}
.provinceName {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}
.provinceItem {
  margin: 15px;
}
.yesAndNo {
  display: flex;
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  .yes {
    width: 60px;
    height: 35px;
    text-align: center;
    line-height: 32px;
    border: #19c2ff 1px solid;
    background-color: #19c2ff;
    color: #fff;
    margin-left: 25px;
    cursor: pointer;
    margin-right: 25px;
  }
  .no {
    width: 60px;
    height: 35px;
    line-height: 32px;
    cursor: pointer;
    text-align: center;
    border: #ccc 1px solid;
    background-color: #fff;
  }
}
.switch {
  margin: 20px 0;
}
.submit {
  padding-top: 20px;
  border-top: 1px solid #eee;
  margin-bottom: 50px;
  span {
    background-color: #19c2ff;
    padding: 5px 10px;
    margin-right: 10px;
    color: #fff;
    &:last-child {
      background-color: #1bc6a3;
    }
  }
}

.el-popper {
  height: 260px;
  overflow: auto;
}
</style>